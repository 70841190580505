@include url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");

html, body {
  font-family: 'Space Mono', monospace;
  font-weight: 400;
}

.App {
  min-height: calc(100vh - 3rem);
  max-width: 100vw;
  position: relative;
  overflow: hidden;  
  --s: 50px;
  --c: #191b22;
  --_s: calc(2*var(--s)) calc(2*var(--s));
  --_g: 35.36% 35.36% at;
  --_c: #0000 66%,#20222a 68% 70%,#0000 72%;
  background: 
    radial-gradient(var(--_g) 100% 25%,var(--_c)) var(--s) var(--s)/var(--_s), 
    radial-gradient(var(--_g) 0 75%,var(--_c)) var(--s) var(--s)/var(--_s), 
    radial-gradient(var(--_g) 100% 25%,var(--_c)) 0 0/var(--_s), 
    radial-gradient(var(--_g) 0 75%,var(--_c)) 0 0/var(--_s), 
    repeating-conic-gradient(var(--c) 0 25%,#0000 0 50%) 0 0/var(--_s), 
    radial-gradient(var(--_c)) 0 calc(var(--s)/2)/var(--s) var(--s) var(--c);
  color: #FFF;
  text-align: center;
  padding-top: 3rem;
}

@media(max-width: 576px) {
  .App {
    padding: 3rem 1.5rem 0;
  }
}

.App-logo {
  width: 8rem;
  height: 8rem
}

.container {
  margin: 0 auto;
  background-color: #232426;
  height: calc(100vh - 3rem);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ffde7e;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
@media(min-width: 576px) {
  .container {
    width: 540px
  }
}
@media(min-width: 768px) {
  .container {
    width: 720px
  }
}
@media(min-width: 992px) {
  .container {
    width: 720px
  }
}

.row {
  display: flex;
  padding: 1.5rem;
  border-bottom: 1px dashed #444;
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  justify-content: space-between;
}
.row:nth-child(2) {
  animation-delay: 2s;
}
.row:nth-child(2) button {
  animation-delay: 2.5s;
}
.row:nth-child(3) {
  animation-delay: 4s;
}
.row:nth-child(3) button {
  animation-delay: 4.5s;
}
.row:nth-child(4) {
  animation-delay: 7s;
}
.row:nth-child(4) button {
  animation-delay: 7.5s;
}
.row:nth-child(5) {
  animation-delay: 10s;
}
.row:nth-last-child(1) {
  border-bottom: none;
}
button.randomize, button.reset {
  margin-left: 10px;
  background: #ffde7e;
  border: none;
  border-radius: .25rem;
  font-size: .9rem;
  font-family: inherit;
  font-weight: 700;
  padding: 8px 10px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  animation: fadeIn 1s linear forwards;
}
button.randomize:hover {
  background-color: #fdf2bd;
}
button.reset {
  background: #606060;
  color: #fff;
}
button.reset:hover {
  background-color: #444;
}
button.reset:disabled {
  background: #606060;
  color: #999;
  cursor: initial;
}
.col {
  display: flex;
  /* flex: 1; */
  text-align: left;
  align-items: center;
}
.col.right {
  justify-content: end;
}
@keyframes fadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}
.random {
  border: none;
  padding: .5rem;
  border-radius: .25rem;
  text-transform: uppercase;
  background: #f4e1f9;
  color: #282c34;
  cursor: pointer;
  border-radius: 50%;
}
.random img {
  width: 6rem
}
.random:hover {
  background: #665fcc;
}

p {
  margin: 0.5rem 0;
}